import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './page-title.css';

const PageTitle = ({ children, as = 'span' }) => {
  return <Text as={as}>{children}</Text>;
};

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
};

export default PageTitle;
